<template>
  <div class="age-check">
    <div v-if="!termsOverlayOpen">
      <div class="box h-overflow-scroll">
        <div align="center"><img src="@/assets/images/logo_jaegermeister.png" class="logo" /></div>
        <div class="age-check-input" v-if="ageCheckConfirmed === null">
          <p>{{ $t('age_check.intro') }}</p>
          <br/>
          <p class="is-text-bold is-uppercase">{{ $t('age_check.enter_birthday') }}</p>
          <br/>
          <div class="input-wrap">
            <div class="label-wrap white">
              <div class="label">{{ $t('age_check.day') }}</div>
            </div>
            <input class="white" type="number" inputmode="numeric" pattern="[0-9]*" maxlength="2" :class="['input', { 'validated' : errorDay === false }]" v-model="day" :placeholder="$t('age_check.day_placeholder')" ref="day">
          </div>
          <div class="input-wrap">
            <div class="label-wrap">
              <div class="label">{{ $t('age_check.month') }}</div>
            </div>
            <input class="white" @focus="correctDayInput()" type="number" inputmode="numeric" pattern="[0-9]*" :class="['input', { 'validated' : errorMonth === false }]" v-model="month" :placeholder="$t('age_check.month_placeholder')" ref="month">
          </div>
          <div class="input-wrap">
            <div class="label-wrap">
              <div class="label">{{ $t('age_check.year') }}</div>
            </div>
            <input class="white" @focus="correctMonthInput()" type="number" inputmode="numeric" pattern="[0-9]*" :class="['input large', { 'validated' : errorYear === false }]" v-model="year" :placeholder="$t('age_check.year_placeholder')" ref="year">
          </div>
          <p v-if="errorDay" class="is-text-orange">{{ $t('age_check.error_day_message') }}</p>
          <p v-if="errorMonth" class="is-text-orange">{{ $t('age_check.error_month_message') }}</p>
          <p v-if="errorYear" class="is-text-orange">{{ $t('age_check.error_year_message') }}</p>
        </div>
      <div v-else-if="ageCheckConfirmed === false">
        <h1>{{ $t('age_check.failed') }}</h1>
      </div>
      <!-- <div class="debug-buttons">
        <span v-if="currentStep != 'fight'" @click="currentStep = 'fight'">Fight</span>
        <span @click="currentStep = 'prologue'">Weiter</span>
      </div> -->
      <PrivacyPolicy class="privacy-bar"/>
    </div>
    </div>
    <TermsOverlay class="is-scrollable" v-else/>
  </div>
</template>

<script>
import moment from 'moment'
import PrivacyPolicy from '@/components/PrivacyPolicy'
import TermsOverlay from '@/components/TermsOverlay'
export default {
  components: { PrivacyPolicy, TermsOverlay },
  data () {
    return {
      ageCheckConfirmed: null,
      day: '',
      month: '',
      year: '',
      errorDay: null,
      errorMonth: null,
      errorYear: null
    }
  },
  computed: {
    termsOverlayOpen () {
      return this.$store.state.termsOverlay
    }
  },
  methods: {
    removeScroll () {
      document.addEventListener('touchmove', function(e) {
      if (e.cancelable) {
        e.preventDefault();
      }
      }, { passive: false });
      console.log('scroll removed')
    },
    validateBirthday () {
      if (this.errorDay === false && this.errorMonth === false && this.errorYear === false) {
        const birthday = moment(`${this.day}.${this.month}.${this.year}`, "DD.MM.YYYY")
        const age = moment.duration(moment().diff(birthday)).asYears()

        if (age >= 18) {
          this.currentStep = 'prologue'
          this.removeScroll()
        } else {
          this.ageCheckConfirmed = false
        }
      } else {
        console.log('not validating')
      }
    },
    correctDayInput () {
      if (this.day.length === 1) {
        this.day = `0${this.day}`
      }
    },
    correctMonthInput () {
      if (this.month.length === 1) {
        this.month = `0${this.month}`
      }
    }
  },
  watch: {
    day () {
      if (this.day.length === 2 && parseInt(this.day) > 0 && parseInt(this.day) <= 31) {
        this.$refs.month.focus()
        this.errorDay = false
        this.validateBirthday()
      } else {
        if (this.day.length > 1) {
          this.errorDay = true
        }
      }
    },
    month () {
      if (this.month.length === 2 && parseInt(this.month) > 0 && parseInt(this.month) <= 12) {
        this.$refs.year.focus()
        this.errorMonth = false
        this.validateBirthday()
      } else {
        if (this.month.length > 1) {
          this.errorMonth = true
        }
      }
    },
    year () {
      if (this.year.length === 4 && parseInt(this.year) > 999 && parseInt(this.year) <= moment().year()) {
        this.errorYear = false
        this.validateBirthday()
      } else {
        if (this.year.length >= 4 || parseInt(this.year) > moment().year()) {
          this.errorYear = true
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
.input-wrap
  position: relative
  display: flex

  .label-wrap
    flex: 0 2rem

    .label
      text-transform: uppercase
      position: absolute
      font-size: 1rem
      transform: rotate(270deg) translateX(-100%)
      top: 0
      left: 0
      transform-origin: top left
      padding-right: 1.5rem
      letter-spacing: 0.2rem
    
  input
    width: 16rem
    height: 9rem
    font-size: 8rem
    margin-bottom: 3rem
    opacity: .75
    color: white
    font-family: 'Meister-Bold', Helvetica, Arial, sans-serif

    &:focus
      opacity: 1 

    &.large
      width: 24rem

.age-check-input
  margin-top: 1rem

.age-check
  background-size: cover

p
  font-size: 1.8rem

h1
  text-align: center

.box
  background-image: url("~@/assets/images/bg-age-check.svg")
  background-repeat: no-repeat
  background-position: right center
  background-attachment: fixed
  background-size: cover

.debug-buttons
  position: fixed
  bottom: 10px
  right: 10px

  span
    margin: 8px

</style>
