<template>
  <div class="prologue-start">
    <div align="center">
      <img
        src="@/assets/images/logo_jaegermeister.png"
        class="logo"
      />
    </div>
    <div
      align="center"
      class="header is-size-large is-text-bold is-uppercase"
    >
      <div>{{ $t("prologue.start.who_pays") }}</div>
      <div>{{ $t("prologue.start.the_next") }}</div>
      <div>{{ $t("prologue.start.round") }}</div>
      <div class="subline is-size-small">Let the game decide!</div>
    </div>

    <div class="move-in-shot">
      <img
        :src="glassImage"
        class="shot responsive-image"
      />
    </div>
    <div class="bottom-set-shot">
      <div
        class="button"
        @click="emitSetPrologueStep('intro')"
      >
        {{ $t("prologue.start.cta") }}
      </div>
      <div align="center">
        <img
          src="@/assets/images/powered-by-pocket-rocket.svg"
          class="powered_by"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    glassImage() {
      const glassColor = this.$store.state.glassColor;
      return require(`@/assets/images/ice_cold_shot_${glassColor}_start.png`);
    },
  },
  methods: {
    playBackgroundSound() {
      this.backgroundSound.volume = 0.3;
      this.backgroundSound.loop = true;
      this.backgroundSound.play();
    },
    emitSetPrologueStep(step) {
      this.clickSound.play();
      this.$emit("setPrologueStep", step);
    },
  },
  watch: {
    currentStep() {
      if (this.currentStep === "prologue") {
        this.playBackgroundSound();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"

.prologue-start
  position: relative
  z-index: 10
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  

.stripe
  height: 12px
  background-color: $color-orange
  width: 80%
  margin-left: 1rem
  margin-top: -2rem
  margin-bottom: 1rem

.subline
  margin-top: 2rem


.powered_by
  width: 200px
  margin-top: 1rem

.responsive-image
  max-width: 100%
  height: auto

@keyframes moveInRight
  0%
    opacity: 0
    transform: translateX(100rem)

  100%
    opacity: 1
    transform: none

@keyframes fadeInScale
  0%
    opacity: 0
    scale: 2

  100%
    opacity: 1
    scale: 1
</style>