<template>
  <div @click="openOverlay" class="privacy-container">
    <p>PRIVACY POLICY + TERMS AND CONDITIONS</p>
  </div>

</template>

<script>
export default {
    data () {
    return {
      url: ''
    }
  },
  computed: {
    country () {
      return this.$store.state.country
    },
    termsOverlayOpen () {
      return this.$store.state.termsOverlay
    }
  },
  methods: {
    openOverlay () {
      this.$store.commit('setTermsOverlay', true)
    }
  }
}
</script>
<style lang="sass" scoped>
@import "../assets/sass/main.sass"

.privacy-container
  align-items: center
  justify-content: center
  text-align: center
  z-index: 300
  padding: 2rem
  cursor: pointer
  color: --footer-color
  position: absolute
  bottom: 0
  width: 100%
  left: 0
  
</style>