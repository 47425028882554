import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import swipeSound from '@/assets/sounds/swipe.mp4'
import clickSound from '@/assets/sounds/click.mp3'
import countdownSound from '@/assets/sounds/countdown.mp3'
import freezeSound from '@/assets/sounds/freeze.mp3'
import backgroundSound from '@/assets/sounds/background.mp3'
import wonSound from '@/assets/sounds/won.mp3'

export const store = new Vuex.Store({
  state: {
    currentStep: 'ageCheck',
    namePlayerOne: null,
    namePlayerTwo: null,
    session: null,
    gamesPlayed: 0,
    glassColor: 'green',
    language: 'en',
    termsOverlay: false,
    country: null,
    sounds: {
      swipeSound: new Audio(swipeSound),
      clickSound: new Audio(clickSound),
      countdownSound: new Audio(countdownSound),
      freezeSound: new Audio(freezeSound),
      backgroundSound: new Audio(backgroundSound),
      wonSound: new Audio(wonSound)
    },
  },
  mutations: {
    setLanguage (state, val) {
      state.language = val
    },
    setCountry (state, val) {
      state.country = val
    },
    setGlassColor (state, val) {
      state.glassColor = val
    },
    setCurrentStep (state, val) {
      state.currentStep = val
    },
    setNamePlayerOne (state, val) {
      state.namePlayerOne = val
    },
    setNamePlayerTwo (state, val) {
      state.namePlayerTwo = val
    },
    setSession (state, session) {
      state.session = session
    },
    increaseGamesPlayed (state) {
      state.gamesPlayed += 1
    },
    setTermsOverlay (state, val) {
      state.termsOverlay = val
    }
  }
})

export default store
