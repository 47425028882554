import FingerprintJS from '@fingerprintjs/fingerprintjs'
const { v4: uuidv4 } = require('uuid')
const client = require('@/client')

export default {
  data () {
    return {
      fingerprint: null,
      userData: null
    }
  },
  computed: {
    currentGameId () {
      if (this.$store.state.country === 'nz') {
        return process.env.VUE_APP_GAME_ID_NZ
      }

      // default
      return process.env.VUE_APP_GAME_ID
    }
  },
  methods: {
    async getFingerprintAndInitializeSession (campaignId) {
      // Get the visitor identifier when you need it.
      const fp = await FingerprintJS.load()
      const result = await fp.get()

      if (result) {
        this.userData = {
          screen_resolution: result.components.screenResolution.value,
          platform: result.components.platform.value,
          browser_languages: result.components.languages.value,
          timezone: result.components.timezone.value,
          browser_vendor: result.components.vendor.value
        }

        this.findSession(campaignId)
      }
    },
    findSession (campaignId) {

      if (!this.currentGameId) {
        console.log("No game id retrieved. Cannot track.")
        return false
      }

      const uuid = uuidv4()
      let fingerprint = null

      const identifier = `BRAND_ADVENTURES_SESSION_${this.currentGameId}`
      let sessionId = localStorage.getItem(identifier)

      if (!sessionId) {
        localStorage.setItem(identifier, uuid)
        fingerprint = uuid
        console.log("creating session")
      } else {
        fingerprint = sessionId
      }

      this.$store.commit('setSession', fingerprint)

      this.createUpdateSession(fingerprint, campaignId)
    },
    async createUpdateSession(identifier, campaignId) {
      try {
        const res = await client.post(`public/games/${this.currentGameId}/sessions`,
          { identifier: identifier, campaign_id: campaignId, user_data: this.userData }
        )
        if (res) {
          console.log(res)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
