<template>
  <div class="prologue-intro container">
    <div class="bottom-set">
      <div class="explain-box">
        <div align="center" class="headline is-text-bold is-uppercase is-size-medium">{{ $t('prologue.intro.headline') }}</div>
        <div class="rules">
          <div class="slide slide-1" v-if="currentSlide === 1">
            <div class="left">
              <img src="@/assets/images/icon-mobile-phone.svg" />
            </div>
            <div class="right">
              {{ $t('prologue.intro.slide_1') }}
            </div>
          </div>
          <div class="slide slide-2" v-if="currentSlide === 2">
            <div class="left">
              <img src="@/assets/images/icon-fight.svg" />
            </div>
            <div class="right">
              {{ $t('prologue.intro.slide_2') }}
            </div>
          </div>
          <div class="slide slide-3" v-if="currentSlide === 3">
            <div class="left">
              <img src="@/assets/images/icon-shots.svg" />
            </div>
            <div class="right">
              {{ $t('prologue.intro.slide_3') }}
            </div>
          </div>
        </div>
        <div class="button" v-if="currentSlide === 3" @click="goToChooseName()">
          {{ $t('prologue.intro.cta') }}
        </div>
        <div class="button" v-else @click="currentSlide += 1">
          {{ $t('prologue.intro.next') }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentSlide: 1
    }
  },
  methods: {
    goToChooseName () {
      this.clickSound.play()
      this.currentStep = 'chooseName'
    }
  },
  watch: {
    currentSlide () {
      this.swipeSound.play()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"
.prologue-intro
  z-index: 10

.headline
  margin-bottom: 2rem

.rules
  border-top: 2px solid $color-white
  padding: 2rem 0
  font-size: 1.7
  line-height: 1.3

.slide
  display: grid
  grid-template-columns: minmax(7rem, 23%) 1fr

  .left img
    width: 7rem

  .right
    height: 9rem
    padding-right: 1.5rem

.explain-box
  color: $color-white
</style>
