<template>
  <div class="choose-name container h-overflow-scroll" :style="`background-image: url(${backgroundImage}); background-repeat: no-repeat; background-size: cover;`">
    <div class="is-text-bold is-text-orange is-uppercase is-size-small">{{ $t('choose_name.headline') }}</div>
    <div class="gap-4" />

    <div class="input-wrap">
      <img src="@/assets/images/name-indicator.svg" class="name-indicator" />
      <input type="text" class="input is-text-bold" placeholder="Player One" v-model="namePlayerOne">
    </div>

    <div class="input-wrap">
      <img src="@/assets/images/name-indicator.svg" class="name-indicator bottom" />
      <input type="text" class="input is-text-bold" placeholder="Player Two" v-model="namePlayerTwo" v-on:keyup.enter="goToFight()">
    </div>
    <div class="gap-4" />
    <div class="button" @click="goToFight()">{{ $t('choose_name.cta') }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    backgroundImage () {
      return require('@/assets/images/bg-age-check.svg')
    },
    namePlayerOne: {
      get () {
        return this.$store.state.namePlayerOne
      },
      set (val) {
        this.$store.commit('setNamePlayerOne', val)
      }
    },
    namePlayerTwo: {
      get () {
        return this.$store.state.namePlayerTwo
      },
      set (val) {
        this.$store.commit('setNamePlayerTwo', val)
      }
    }
  },
  methods: {
    goToFight () {
      document.querySelectorAll('.input').forEach((i) => i.blur());
      this.clickSound.play()
      this.updateSessionProgress(
        { step: 'game_started', choosen_names: [this.namePlayerOne, this.namePlayerTwo] }
      )
      this.currentStep = 'fight'
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"
.choose-name
  padding: 4rem 3rem

  .input-wrap
    width: 100%
    .name-indicator
      width: 10%
      height: 5.4rem
      margin-bottom: -2.7rem
      margin-right: 3%
      &.bottom
        transform: rotate(180deg)
</style>
