<template>
  <div class="exit container">
    <div class="box">
      <div align="center">
        <img src="@/assets/images/logo_jaegermeister.png" class="logo" />
        <h1>{{ $t('exit.claim') }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"
.exit
  position: relative
  background-image: url("~@/assets/images/bg-age-check.svg")
  background-repeat: no-repeat
  background-position: right center
  background-attachment: fixed
  background-size: cover
</style>
