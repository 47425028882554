import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  en: {
    age_check: {
      intro: 'To access our Jägermeister websites you must be of legal drinking age in your country of access or older.',
      intro_confirmed_age: 'Slide up to enter the game',
      enter_birthday: 'Please enter your date of birth:',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      day_placeholder: 'DD',
      month_placeholder: 'MM',
      year_placeholder: 'YYYY',
      failed: 'We\'re sorry! But you must have reached the legal drinking age to participate in this game.',
      error_day_message: 'Please enter a valid day',
      error_month_message: 'Please enter a valid month',
      error_year_message: 'Please enter a valid year'
    },
    prologue: {
      start: {
        who_pays: 'Who pays',
        the_next: 'The next',
        round: 'Round ?',
        cta: 'Play now'
      },
      intro: {
        headline: 'How to play',
        slide_1: 'Two players compete against each other. Now place your device in the middle of the table between you and your opponent.',
        slide_2: 'Let\'s Fight! Move your deer by tapping the marker on your half of the screen until the opponent is pushed off the field.',
        slide_3: 'The first player to win two rounds wins the game. The next Jägermeister Shot is paid by the loser!',
        next: 'Next',
        cta: 'To the game'
      },
    },
    choose_name: {
      headline: 'Enter player',
      cta: 'Play now'
    },
    fight: {
      round: 'Round',
      score_for: 'Point for'
    },
    finish: {
      pays: 'pays',
      the_next: 'the next',
      icecold: 'ice cold',
      round: 'round',
      cta: 'Play again',
      cta_end: 'Exit'
    },
    exit: {
      claim: 'That\'s it for today! You\'re welcome to play again tomorrow for a rematch.'
    }
  },
  de: {
    age_check: {
      intro: 'Um unsere Jägermeister Websites besuchen zu können musst du mindestens 18 Jahre alt sein.',
      intro_confirmed_age: 'Nach oben wischen um das Spiel zu betreten',
      enter_birthday: 'Bitte gib Dein Geburtsdatum ein:',
      day: 'Tag',
      month: 'Monat',
      year: 'Jahr',
      day_placeholder: 'TT',
      month_placeholder: 'MM',
      year_placeholder: 'JJJJ',
      failed: 'Es tut uns leid! Aber Du musst das gesetzliche Mindestalter erreicht haben, um an diesem Spiel teilnehmen zu können.',
      error_day_message: 'Bitte gib einen gültigen Tag ein',
      error_month_message: 'Bitte gib einen gültigen Monat ein',
      error_year_message: 'Bitte gib ein gültiges Jahr ein'
    },
    prologue: {
      start: {
        who_pays: 'Wer zahlt',
        the_next: 'Die nächste',
        round: 'Runde ?',
        cta: 'Jetzt spielen'
      },
      intro: {
        headline: 'So wird gespielt',
        slide_1: 'Es treten zwei Spieler gegeneinander an. Lege jetzt Dein Device in die Tischmitte zwischen Dich und Deinen Mitspieler.',
        slide_2: 'Let\'s Fight! Bewegt Euch mit Euren Hirschen, indem Ihr auf die Markierung auf Eurer Hälfte des Bildschirms tippt, bis der Gegner von dem Spielfeld gedrängt wird.',
        slide_3: 'Wer zuerst zwei Runden für sich entschieden hat, gewinnt das Spiel. Den nächsten Jägermeister Shot zahlt der Verlierer!',
        next: 'Weiter',
        cta: 'Zum Spiel'
      },
    },
    choose_name: {
      headline: 'Spieler eingeben',
      cta: 'Jetzt spielen'
    },
    fight: {
      round: 'Runde',
      score_for: 'Punkt für'
    },
    finish: {
      pays: 'zahlt',
      the_next: 'die nächste',
      icecold: 'eiskalte',
      round: 'Runde',
      cta: 'Nochmal spielen',
      cta_end: 'Exit'
    },
    exit: {
      claim: 'Das war\'s für heute! Ihr könnt gerne morgen wieder spielen und ein Rematch austragen.'
    }
  }
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'de',
  messages
})

export default i18n
